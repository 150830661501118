import React, { useState, useRef } from 'react';
import {
  Box,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  TableBody,
  Card,
  CardContent,
  ClickAwayListener,
  Grow,
  MenuList,
  MenuItem,
  Paper,
  Popper,
  Stack,
  useTheme,
  ButtonGroup,
} from '@mui/material';
import { Skeleton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faEllipsisV, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { Trans, useTranslation } from 'react-i18next';
import { components } from 'openapi-types';
import { ConfirmationDialog } from 'component/base/ConfirmDialog';
import { useDeleteSiteTemplate, useSiteTemplates } from 'api/site';
import { formatDate } from 'utils/dateFormat';
import { useNavigate } from 'react-router-dom';
import { linkHelper } from 'linkHelper';
import { RoleGuard } from 'component/base/RoleGuard';
import { usePaginatedListState } from 'component/hooks/usePaginatedListState';
import { TableHeadCell } from 'component/base/TableHeadCell';
import PaginationControls from 'component/base/PaginationControls';

export default function RowSkeleton() {
  const rows = () => {
    return Array.from({ length: 3 }).map((v, i) => (
      <TableRow key={i}>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
        <RoleGuard roles={['billing_admin']} type="block">
          <TableCell>
            <Skeleton />
          </TableCell>
        </RoleGuard>
      </TableRow>
    ));
  };

  return <>{rows()}</>;
}

type SiteTemplateRowProps = {
  readonly template: NonNullable<
    NonNullable<components['schemas']['ListSiteTemplatesResponse']['result']>['site_templates']
  >[0];
};

function TemplateRow({ template }: SiteTemplateRowProps) {
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [openTableMenu, setOpenTableMenu] = useState<boolean>(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<boolean>(false);
  const { t } = useTranslation();
  const { mutateAsync: deleteTemplate } = useDeleteSiteTemplate();
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <TableRow key={template.template_id}>
      <TableCell>{template.name}</TableCell>
      <TableCell>
        {template.created_at
          ? formatDate({
              date: new Date(template.created_at).toISOString(),
            })
          : '-'}
      </TableCell>
      <TableCell>{template.created_from?.domain ?? '-'}</TableCell>
      <TableCell>{template.config?.wordpress?.version ?? '-'}</TableCell>
      <RoleGuard roles={['billing_admin']} type="block">
        <TableCell
          sx={{
            textAlign: 'center',
          }}
        >
          <ButtonGroup>
            <Button
              variant="text"
              color="primary"
              onClick={() => {
                navigate(
                  linkHelper.sites.create.getLink({
                    templateId: template.template_id ?? '',
                  })
                );
              }}
            >
              {t('create_site')}
            </Button>
            <Button
              variant="text"
              color="primary"
              onClick={() => {
                navigate(
                  linkHelper.sites.viewTemplate.getLink({
                    templateId: template.template_id ?? '',
                  })
                );
              }}
            >
              {t('view')}
            </Button>
            <Button
              ref={anchorRef}
              variant="text"
              color="primary"
              sx={{
                minHeight: '2.75rem',
                width: '30px',
              }}
              fullWidth
              onClick={async () => {
                setOpenTableMenu(true);
              }}
            >
              <FontAwesomeIcon size="lg" icon={faEllipsisV} onClick={() => {}} />
              <ConfirmationDialog
                action="delete"
                onConfirm={async () => {
                  deleteTemplate(template.template_id ?? '', {
                    onSuccess: () => {
                      setOpenConfirmationDialog(false);
                    },
                  });
                }}
                description={
                  <Trans
                    i18nKey="delete_description"
                    values={{ name: template.name ?? template.template_id }}
                    components={[<strong key="strong" />]}
                  />
                }
                onClose={() => setOpenConfirmationDialog(false)}
                open={openConfirmationDialog}
                title={t('delete_template')}
              />
              <Popper
                popperOptions={{
                  placement: 'bottom-start',
                }}
                open={openTableMenu}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={() => setOpenTableMenu(false)}>
                        <MenuList autoFocusItem={openTableMenu} id="site-templates-list">
                          <MenuItem
                            onClick={e => {
                              e.stopPropagation();
                              e.preventDefault();
                              setOpenTableMenu(false);
                              setOpenConfirmationDialog(true);
                            }}
                          >
                            <Stack spacing={1} direction="row" alignItems="center">
                              <FontAwesomeIcon color={theme.palette.primary.main} icon={faTrash} />{' '}
                              <Box>{t('delete_template')}</Box>
                            </Stack>
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </Button>
          </ButtonGroup>
        </TableCell>
      </RoleGuard>
    </TableRow>
  );
}

export function SiteTemplatesList() {
  const { t } = useTranslation();

  const tableState = usePaginatedListState();
  const { data, status, isLoading } = useSiteTemplates(tableState);

  const templates = (data?.data.result?.site_templates ?? []).map(template => template);

  const renderTableRows = () => {
    if (status === 'pending') {
      return <RowSkeleton />;
    }

    return templates.map(template => {
      if (!template) {
        return null;
      }
      return <TemplateRow key={template.template_id ?? ''} template={template} />;
    });
  };

  return (
    <Card sx={{ marginTop: '0px !important' }}>
      <CardContent>
        <TableContainer>
          <Table aria-label="Site Templates List Table">
            <TableHead>
              <TableRow>
                <TableHeadCell tableState={tableState} column="name" label={t('template_name')} />
                <TableHeadCell tableState={tableState} column="created_at" label={t('created')} />
                <TableCell>{t('created_from')}</TableCell>
                <TableCell>{t('wp_version')}</TableCell>
                <RoleGuard roles={['billing_admin']} type="block">
                  <TableCell width="190px">{t('actions')}</TableCell>
                </RoleGuard>
              </TableRow>
            </TableHead>
            <TableBody>{renderTableRows()}</TableBody>
          </Table>
        </TableContainer>
        {!isLoading && (
          <PaginationControls
            page={tableState.params.page}
            totalRowCount={data?.data.metadata?.total || 0}
            perPage={tableState.params.perPage}
            onPageChange={page => tableState.setPage(page)}
            onPerPageChange={perPage => tableState.setPerPage(perPage)}
          />
        )}
      </CardContent>
    </Card>
  );
}
